import { FC, useEffect, useRef } from "react";
import { isNil } from "lodash";
import {
  createErrorMessage,
  ErrorMessageBus,
  IMessageBus,
} from "@roketus/web-toolkit";
import { Container, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { getContainer } from "../../diContainer/container";
import { isOS } from "../../adapters/OS/isOS";
import { useQuery } from "../../adapters/hooks/useQuery";
import {
  ANDROID_CARD_DOWNLOAD,
  IOS_CARD_DOWNLOAD,
} from "../../domain/specs/urlParams";
import { AN_DEMO_CARD_DOWNLOADED_BY_QR } from "../../domain/specs/userAnalyticsCode";
import { AutoUserIDSetter } from "../AutoUserIDSetter";
import PlaneSvg from "../PlaneSvg";

const isIOSSystem = isOS();

export const DownloadCard: FC = () => {
  let query = useQuery();
  const isDownloading = useRef(false);
  const iOSUrl = query.get(IOS_CARD_DOWNLOAD);
  const androidUrl = query.get(ANDROID_CARD_DOWNLOAD);

  const errorMessageBus = getContainer().getDependency(
    "errorMessageBus"
  ) as ErrorMessageBus;

  const isValidParams = !isNil(iOSUrl) && !isNil(androidUrl);

  useEffect(() => {
    const download = async () => {
      try {
        if (!isValidParams) return;

        if (isDownloading.current) return;

        window.location.href = isIOSSystem ? iOSUrl : androidUrl;

        const messageBus = getContainer().getDependency(
          "messageBus"
        ) as IMessageBus;
        messageBus.send({
          type: "GAanalyticsEvent",
          message: AN_DEMO_CARD_DOWNLOADED_BY_QR,
          source: "wizardService",
        });
        isDownloading.current = true;
      } catch (e) {
        const msg = createErrorMessage({
          message: "Failed to download card",
          error: e as Error,
          source: "DownloadCard",
        });
        errorMessageBus.send(msg);
      }
    };
    download();
  }, [errorMessageBus, iOSUrl, androidUrl, isValidParams]);

  return isValidParams ? (
    <AutoUserIDSetter>
      <Container maxWidth="lg" sx={{ p: 2 }}>
        <Paper
          sx={{
            maxWidth: 380,
            padding: "1.5rem 0 1rem",
            margin: "0 auto",
            borderRadius: "12px",
            boxShadow: "0px 1px 12px 0px #00000026",
          }}
        >
          <Typography px={2}>
            {isIOSSystem ? (
              <span>
                <b>The Atomic Loyalty Demo card</b> is now being downloaded to
                your mobile wallet.
                <br />
                You may close the window when the downloading is finished.
              </span>
            ) : (
              "Please wait you'll be signed in..."
            )}
          </Typography>
          <PlaneSvg />
        </Paper>
      </Container>
    </AutoUserIDSetter>
  ) : null;
};
