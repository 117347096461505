import { Routes, Route, Navigate } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { getContainer } from "../diContainer/container";
import { useObservable } from "@roketus/web-toolkit";
import { AuthService } from "../boundary/AuthService/AuthService";
import { Analytics } from "./pages/Analytics";
import { DownloadCard } from "./pages/DownloadCard";

export const Navigation = () => {
  const authService = getContainer().getDependency(
    "authService"
  ) as AuthService;

  const authData = useObservable(authService.state$);

  return (
    <>
      {authData && (
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/download-card" element={<DownloadCard />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      )}
    </>
  );
};
