import axios from "axios";
import { getContainer } from "../../../diContainer/container";

import {
  ErrorMessageBus,
  IMessageBus,
  StateSubject,
  createErrorMessage,
} from "@roketus/web-toolkit";
import { IListTemplateService } from "../../../boundary/IListTemplateService";
import {
  IWizardData,
  IWizardService,
  WizardStep,
  WizardStepIndex,
} from "../../../boundary/IWizardService";
import { PositiveMessageEntity } from "../../../domain/entities/messages/positiveEntity";
import {
  BONUS_SUBMITTED,
  CHECK_DEVICE_NOTIFICATION,
  PROMO_CODE_RECEIVED,
  PROMO_CODE_USED,
  SUCCESS_SUBMIT_FEEDBACK_FORM,
} from "../../../domain/specs/positiveCodes";
import {
  ERROR_CODE_FAILED_TO_ADD_BONUSES,
  ERROR_CODE_FAILED_TO_GET_PROMO_CODE,
  ERROR_CODE_FAILED_TO_USE_PROMO_CODE,
} from "../../../domain/specs/errorCodes";
import { sdk, ISDK } from "@roketus/loyalty-js-sdk";
import {
  ANDROID_CARD_DOWNLOAD,
  GA_USER_ID,
  IOS_CARD_DOWNLOAD,
} from "../../../domain/specs/urlParams";
import { ICardService } from "../../../boundary/ICardService";
import { isUndefined } from "lodash";
import { ITemplateRepository } from "../../../boundary/TemplateRepository/ITemplateRepository";
import {
  AN_DEMO_BONUS_RECEIVED,
  AN_DEMO_CARD_CREATED,
  AN_DEMO_CARD_INSTALLED,
  AN_DEMO_CARD_TRY_CREATE,
} from "../../../domain/specs/userAnalyticsCode";
import { IUserAnalyticsService } from "../../../boundary/IUserAnalyticsService";
import { IFeatureToggleService } from "../../../boundary/IFeatureToggleService";
import { IPassServiceV2 } from "../../../boundary/IPassService";
import { convertToAddStampCardBonusDTO } from "../../data/GenericPassEntity/convertToAddStampCardBonusDTO";
import { convertToGetPromoCodeDTO } from "../../data/GenericPassEntity/convertToGetPromoCodeDTO";
import { PassItem } from "../../../boundary/PassRepository/PassItem";
import {
  EVENT_TICKET_TEMPLATES_IDS,
  STAMP_TEMPLATES_IDS,
} from "../../../components/PreviewSection/CardIOSPreview/previewData/templatesConfig";
import { getStepsByTemplate, wizardSteps } from "./wizardSteps";
import { convertToUsePromoCodeDTO } from "../../data/GenericPassEntity/convertToUsePromoCodeDTO";

const issuer = process.env.REACT_APP_ISSUER;

export const checkIfStampCard = (templateId: string = ""): boolean => {
  return STAMP_TEMPLATES_IDS.includes(templateId);
};

export const checkIfEventTicket = (templateId: string = ""): boolean => {
  return EVENT_TICKET_TEMPLATES_IDS.includes(templateId);
};

export const getSteps = (templateId?: string): WizardStep[] => {
  const templateStepList = getStepsByTemplate(templateId);
  const steps = wizardSteps.filter((step) =>
    templateStepList.includes(step.key)
  );

  return steps;
};

export const init = (messageBus: IMessageBus): IWizardService => {
  const initialState: IWizardData = {
    isLoaded: true,
    loading: false,
    failed: false,
    activeStep: 0,
    downloadIOSCardUrl: "",
    downloadAndroidCardUrl: "",
    passId: null,
    cardNumber: "",
    downloadCardUrl: "",
    templateType: "",
    businessName: "",
    template: null,
    loadingTemplatePreview: false,
    isExecutedTransaction: false,
    isPromocodeReceived: false,
    isPromocodeUsed: false,
  };

  const stateMachine = new StateSubject<IWizardData>(initialState);

  const stepActionMap: Record<WizardStepIndex, any> = {
    0: async () => {
      await updateTemplateIfNeeded();
      await setUpTemplate();
      await createCard();
    },
    1: () => {
      const messageBus = getContainer().getDependency(
        "messageBus"
      ) as IMessageBus;
      messageBus.send({
        type: "GAanalyticsEvent",
        message: AN_DEMO_CARD_INSTALLED,
        source: "wizardService",
      });
    },
    2: () => {
      const messageBus = getContainer().getDependency(
        "messageBus"
      ) as IMessageBus;
      messageBus.send({
        type: "GAanalyticsEvent",
        message: AN_DEMO_BONUS_RECEIVED,
        source: "wizardService",
      });
    },
    3: () => null,
    4: () => null,
    5: () => null,
    6: () => null,
    7: () => null,
    8: () => null,
    9: () => null,
  };

  const updateTemplateIfNeeded = async () => {
    const listTemplateService = getContainer().getDependency(
      "listTemplateService"
    ) as IListTemplateService;

    const selectedTemplate: string = stateMachine.state$.value.templateType;

    if (checkIfEventTicket(selectedTemplate)) {
      stateMachine.setState({
        loading: true,
      });

      await listTemplateService.updateTemplateRelevantDate(
        selectedTemplate.toString()
      );

      stateMachine.setState({
        loading: false,
      });
    }
  };

  const setUpTemplate = async () => {
    const listTemplateService = getContainer().getDependency(
      "listTemplateService"
    ) as IListTemplateService;

    const selectedTemplate: string = stateMachine.state$.value.templateType;

    stateMachine.setState({
      loading: true,
    });

    await listTemplateService.setDefaultTemplate(selectedTemplate.toString());
    stateMachine.setState({
      loading: false,
    });
  };

  const createCard = async () => {
    const messageBus = getContainer().getDependency(
      "messageBus"
    ) as IMessageBus;

    const cardService = getContainer().getDependency(
      "cardService"
    ) as ICardService;

    const featureToggleService = getContainer().getDependency(
      "featureToggleService"
    ) as IFeatureToggleService;
    messageBus.send({
      type: "GAanalyticsEvent",
      message: AN_DEMO_CARD_TRY_CREATE,
      source: "wizardService",
    });

    stateMachine.setState({ loading: true });
    const container = getContainer();

    const loyaltySDK = container.getDependency("loyaltySDK") as ISDK;
    const userAnalyticsService = container.getDependency(
      "userAnalyticsService"
    ) as IUserAnalyticsService;

    if (isUndefined(issuer)) {
      throw new Error("Issuer Not Set in Env");
    }

    const businessName = stateMachine.state$.value.businessName;

    loyaltySDK.user.setIssuer(issuer);
    const data = await loyaltySDK.user.card.createAnonymousUserCard(
      businessName
    );

    const params = new URLSearchParams({
      issuer: issuer || "",
      [GA_USER_ID]: userAnalyticsService.getUserID(),
      jwt: data.cardJwt,
    });

    const androidUrl = `${
      process.env.REACT_APP_PWA_SIGNIN_URL
    }?${params.toString()}`;

    const cardUrlParams = new URLSearchParams({
      [IOS_CARD_DOWNLOAD]: data.iosCardDownloadLink,
      [ANDROID_CARD_DOWNLOAD]: androidUrl,
      [GA_USER_ID]: userAnalyticsService.getUserID(),
    });

    const cardUrl = `${
      process.env.REACT_APP_BASE_URL
    }download-card/?${cardUrlParams.toString()}`;

    const apiKey = await cardService.getApiKey();
    const jwt = await cardService.getJWTByApiKey(apiKey);
    sdk.user.setUserToken(jwt);

    const short_url = await loyaltySDK.shortLink.createLink({
      name: `card link: ${userAnalyticsService.getUserID()}`,
      linkType: "site",
      url: cardUrl,
      meta: {},
      params: {},
    });

    messageBus.send({
      type: "GAanalyticsEvent",
      message: AN_DEMO_CARD_CREATED,
      source: "wizardService",
    });

    const passService = getContainer().getDependency(
      "passService"
    ) as IPassServiceV2;

    const pass = await passService.getLastCard();

    stateMachine.setState({
      loading: false,
      downloadCardUrl: short_url.data.data[0].attributes.url,
      downloadIOSCardUrl: data.iosCardDownloadLink,
      downloadAndroidCardUrl: data.androidCardDownloadLink,
      cardNumber: data.cardNumber,
      passId: pass?.id || null,
      isExecutedTransaction: false,
    });

    await sdk.issuer.setSettings({
      templateId: parseInt(
        featureToggleService.data$.value.getFeatureByName<string>(
          "pwaTemplateID"
        ) || "",
        10
      ),
    });
  };

  const changeStep = (toStepId: WizardStepIndex) => {
    stateMachine.setState({
      activeStep: toStepId,
    });
  };

  const proceedToNextStep = async () => {
    const currentStep: WizardStepIndex = stateMachine.state$.value.activeStep;
    const templateId: string = stateMachine.state$.value.templateType;

    await stepActionMap[currentStep]();

    if (!isUndefined(getSteps(templateId)[currentStep].successMessageData)) {
      const msg: PositiveMessageEntity = {
        data: getSteps(templateId)[currentStep].successMessageData as string,
        message: "",
        source: "wizardService",
        type: "positiveEvent",
      };
      messageBus.send(msg);
    }

    changeStep((currentStep + 1) as WizardStepIndex);
  };

  const addBonus = async () => {
    const cardService = getContainer().getDependency(
      "cardService"
    ) as ICardService;

    stateMachine.setState({
      loading: true,
    });

    const apiKey = await cardService.getApiKey();
    const jwt = await cardService.getJWTByApiKey(apiKey);

    axios
      .put(
        `${process.env.REACT_APP_LOYALTY_API_URL}card/updateBonusAndNotification/${stateMachine.state$.value.cardNumber}`,
        {
          data: {
            attributes: {
              bonus: "9.00",
              notification:
                "You paid 10.00 points from bonus account and got %@ points from USD90 purchase",
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then(() => {
        const msg: PositiveMessageEntity = {
          data: CHECK_DEVICE_NOTIFICATION,
          message: "",
          source: "wizardService",
          type: "positiveEvent",
        };
        messageBus.send(msg);
        stateMachine.setState({
          isExecutedTransaction: true,
        });
      })
      .catch()
      .finally(() => {
        stateMachine.setState({
          loading: false,
        });
      });
  };

  const addStampCardBonus = async () => {
    stateMachine.setState({
      loading: true,
    });

    const container = getContainer();
    const messageBus = container.getDependency("messageBus") as IMessageBus;
    const passService = container.getDependency(
      "passService"
    ) as IPassServiceV2;

    try {
      if (!stateMachine.state$.value.passId) {
        throw new Error("No card found");
      }

      const updatedPass = convertToAddStampCardBonusDTO(
        stateMachine.state$.value.passId
      );

      await passService.updatePass(updatedPass as Partial<PassItem>);

      stateMachine.setState({
        isExecutedTransaction: true,
        loading: false,
      });

      const msg: PositiveMessageEntity = {
        type: "positiveEvent",
        message: "",
        source: "wizardService",
        data: BONUS_SUBMITTED,
      };
      messageBus.send(msg);
    } catch (err) {
      stateMachine.setState({
        loading: false,
      });

      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_FAILED_TO_ADD_BONUSES,
        source: "wizardService",
        message: "Add Pass Bonus Server Error",
        error: err as Error,
      });
      messageBus.send(errorMessage);
    }
  };

  const getPromoCode = async () => {
    stateMachine.setState({
      loading: true,
    });

    const container = getContainer();
    const messageBus = container.getDependency("messageBus") as IMessageBus;
    const passService = container.getDependency(
      "passService"
    ) as IPassServiceV2;

    try {
      if (!stateMachine.state$.value.passId) {
        throw new Error("No card found");
      }

      const updatedPass = convertToGetPromoCodeDTO(
        stateMachine.state$.value.passId
      );

      await passService.updatePass(updatedPass as Partial<PassItem>);

      stateMachine.setState({
        isPromocodeReceived: true,
        loading: false,
      });

      const msg: PositiveMessageEntity = {
        type: "positiveEvent",
        message: "",
        source: "wizardService",
        data: PROMO_CODE_RECEIVED,
      };
      messageBus.send(msg);
    } catch (err) {
      stateMachine.setState({
        loading: false,
      });

      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_FAILED_TO_GET_PROMO_CODE,
        source: "wizardService",
        message: "Get Promo Code Server Error",
        error: err as Error,
      });
      messageBus.send(errorMessage);
    }
  };

  const usePromoCode = async () => {
    stateMachine.setState({
      loading: true,
    });

    const container = getContainer();
    const messageBus = container.getDependency("messageBus") as IMessageBus;
    const passService = container.getDependency(
      "passService"
    ) as IPassServiceV2;

    try {
      if (!stateMachine.state$.value.passId) {
        throw new Error("No card found");
      }

      const updatedPass = convertToUsePromoCodeDTO(
        stateMachine.state$.value.passId
      );

      await passService.updatePass(updatedPass as Partial<PassItem>);

      stateMachine.setState({
        isPromocodeUsed: true,
        loading: false,
      });

      const msg: PositiveMessageEntity = {
        type: "positiveEvent",
        message: "",
        source: "wizardService",
        data: PROMO_CODE_USED,
      };
      messageBus.send(msg);
    } catch (err) {
      stateMachine.setState({
        loading: false,
      });

      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_FAILED_TO_USE_PROMO_CODE,
        source: "wizardService",
        message: "Use Promo Code Server Error",
        error: err as Error,
      });
      messageBus.send(errorMessage);
    }
  };

  const submitFeedbackForm = async (
    data: { name: string; email: string; phone: string },
    cleanUpForm: () => void
  ) => {
    stateMachine.setState({
      loading: true,
    });

    axios
      .post(
        `${process.env.REACT_APP_FEEDBACK_FORM_URL}`,
        {
          ...data,
          action: "contactUs",
        },
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_FEEDBACK_FORM_API_KEY}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      )
      .then(() => {
        cleanUpForm();

        const msg: PositiveMessageEntity = {
          data: SUCCESS_SUBMIT_FEEDBACK_FORM,
          message: "",
          source: "wizardService",
          type: "positiveEvent",
        };
        const messageBus = getContainer().getDependency(
          "messageBus"
        ) as IMessageBus;

        messageBus.send(msg);
      })
      .catch((e) => {
        alert(e?.response?.data?.message || "Error");
      })
      .finally(() => {
        stateMachine.setState({
          loading: false,
        });
      });
  };

  const handlePreviousStep = () => {
    const currentStep = stateMachine.state$.value.activeStep;
    changeStep((currentStep - 1) as WizardStepIndex);
  };

  const selectTemplateType = (templateType: string) => {
    stateMachine.setState({
      templateType,
    });
  };

  const setBusinessName = (businessName: string) => {
    stateMachine.setState({
      businessName,
    });
  };

  const loadTemplatePreview = async (templateId: string) => {
    const templateRepository = getContainer().getDependency(
      "templateRepository"
    ) as ITemplateRepository;

    stateMachine.setState({ loadingTemplatePreview: true });

    try {
      const template = await templateRepository.getById(templateId);
      stateMachine.setState({ loadingTemplatePreview: false, template });
    } catch (e) {
      stateMachine.setState({ loadingTemplatePreview: false, template: null });
    }
  };

  return {
    proceedToNextStep,
    handlePreviousStep,
    selectTemplateType,
    loadTemplatePreview,
    setBusinessName,
    setDefaultTemplateID(templateId: string) {
      stateMachine.setState({ templateType: templateId });
    },
    data$: stateMachine.state$,
    addBonus,
    addStampCardBonus,
    getPromoCode,
    usePromoCode,
    submitFeedbackForm,
  };
};
